.filterIconButton {
  background-color: var(--wv-yellow);
  color: #1da1f2;
  height: 50px;
  width: 50px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
}

.filterIconButton:hover {
  background-color: #e7c800;
}

.popoverPaper {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
  box-shadow: None;
}

.filterIcon {
  height: 20px;
  width: 20px;
}

.filterContainer {
  padding: 5px;
}

.loadingIndicator {
  background-color: #51247f3f;
}

.loadingBar {
  background-color: var(--wv-purple);
}

.filterBadgeRoot {
  position: absolute;
  top: 15px;
  left: 35px;
}

.filterBadge {
  background-color: inherit;
  color: white;
  z-index: 0;
}
