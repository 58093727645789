.myLocationButton {
  background-color: var(--wv-yellow);
  color: #1da1f2;
  height: 35px;
  width: 35px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
}

.myLocationButton:hover {
  background-color: #e7c800;
}
