.alertRoot {
  position: absolute;
  z-index: 1500;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 600px) {
  .alertRoot {
    width: 80%;
  }
}
