.socialsIconButton {
  background-color: var(--wv-yellow);
  color: #1da1f2;
  height: 50px;
  width: 50px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
}

.socialsIconButton:hover {
  background-color: #e7c800;
}

.popoverHeader {
  font-size: 18px;
  width: 255px;
  min-height: 0px;
  padding: 10px;
}

.popoverHeader > a {
  text-decoration: none;
}

.popoverPaper {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
  box-shadow: None;
}

.loadingIndicator {
  background-color: #51247f3f;
}

.loadingBar {
  background-color: var(--wv-purple);
}

.loadMessage {
  width: 265px;
  padding: 5px;
}
