.tableContainer {
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}

.tableContainer::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.tableContainer::-webkit-scrollbar-track {
  background-color: whitesmoke;
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: lightslategray;
}

.tableHeader {
  background-color: #e7c800;
}

.tableRow {
  border-bottom: 1px solid rgb(82, 36, 127, 0.5);
  background-color: white;
}

.icon {
  height: 30px;
  width: 30px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.4));
}

.loadingContainer {
  height: 100px;
  text-align: center;
}
