.card {
  width: 205px;
  height: 90px;
  background: var(--wv-yellow);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.mainWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textWrapper {
  padding-top: 10px;
}

.totalNumber {
  font-weight: bold;
  line-height: 42px;
  color: var(--wv-purple);
  font-size: 30px;
  align-self: center;
  padding-left: 10px;
}

.icon {
  height: 70px;
  width: 70px;
}

.totalText {
  font-size: 18px;
  line-height: 21px;
  color: var(--wv-purple);
  padding-left: 10px;
}
