.sidenav {
  height: 100%;
  width: 240px;
  position: fixed;
  background-color: var(--wv-purple);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.logoContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 125px;
  background-color: white;
}

.logo {
  width: 240px;
}

.menuIconButton {
  z-index: 2;
  position: fixed;
  color: var(--wv-yellow);
  background-color: var(--wv-purple);
  height: 50px;
  width: 50px;
  margin: 10px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
}

.menuIconButton:hover {
  background-color: #4b2274;
}

.drawerPaper {
  width: 240px;
}

.sidenavContent {
  height: calc(100% - 125px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.analyticsBoxes > div {
  margin: 20px 0;
}

.analyticsBoxes > :last-child {
  margin-bottom: 0;
}

.dashboardButton {
  background-color: var(--wv-yellow);
  margin-bottom: 20px;
}

.dashboardButton:hover {
  background-color: #e7c800;
}
