.timelineRoot {
  color: var(--wv-yellow);
  height: 3px;
  padding: 12px 0;
}

.timelineThumb {
  height: 27px;
  width: 27px;
  background: #fff;
  border: 2px solid var(--wv-purple);
  margin-top: -12px;
  margin-left: -13px;
  box-shadow: #ccc 0 2px 2px;
}

.timelineThumb:focus,
.timelineThumb:hover,
.timelineThumb:active {
  box-shadow: #ccc 0 2px 3px 1px;
}

.timelineRail {
  color: var(--wv-purple);
  height: 3px;
  opacity: 1;
  pointer-events: None;
}

.timelineTrack {
  height: 3px;
  pointer-events: None;
}

.timelineMarks {
  background-color: var(--wv-purple);
}

.timelineMarkLabels {
  top: 26px;
}

.bar {
  height: 9px;
  width: 1px;
  background-color: var(--wv-purple);
  margin-left: 1px;
}

.barLeft {
  margin-right: 2px;
}

.barRight {
  margin-right: 1px;
}
