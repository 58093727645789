.card {
  width: 200px;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  margin: 5px 0;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.assetText {
  font-size: 14px;
  font-weight: bold;
}

.locationText {
  font-size: 12px;
}

.assetImageContainer {
  display: flex;
  align-items: center;
  width: 200px;
  height: 94px;
  margin-bottom: 5px;
}

.assetImage {
  min-width: 100%;
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.dateContainer {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 2;
  right: 5px;
  top: 5px;
  height: 10px;
  border-radius: 10px;
  padding: 5px;
  background-color: lightgray;
}

.indexContainer {
  position: absolute;
  z-index: 2;
  right: 5px;
  bottom: 5px;
}
