.statsButton {
  background-color: var(--wv-yellow);
  color: #1da1f2;
  height: 50px;
  width: 50px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
}

.statsButton:hover {
  background-color: #e7c800;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--wv-purple);
  padding: 10px;
  border-radius: 5px;
  width: 500px;
  max-width: 90vw;
}

.form {
  margin: 10px;
  color: white;
}

.colorWhite {
  color: white;
}

/* Account for permanent sidebar at above mobile widths */
@media screen and (min-width: 600px) {
  .modalContainer {
    margin-left: 235px;
  }
}
