.homeInfo {
  text-align: left;
  padding: 20px;
  font-size: 20px;
}

.map {
  position: relative;
  height: 100vh;
}

.centeredContainer {
  display: flex;
  position: fixed;
  left: 0;
  bottom: 2.5%;
  justify-content: center;
  width: 100%;
  height: 55px;
}

.timeline {
  width: 20%;
  min-width: 210px;
  background-color: #fffffff5;
  border-radius: 5px;
  padding: 5px 25px 1px 25px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  z-index: 2;
  margin: 10px;
}

.buttonGroup > * {
  margin-bottom: 10px;
}

.myLocationButton {
  display: flex;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 2.5%;
  z-index: 2;
  margin: 10px 10px 0px 10px;
  height: 55px;
}

.loadingIndicatorRoot {
  position: fixed;
  top: 45%;
}

.loadingIndicatorColor {
  color: var(--wv-purple) !important;
}

.topInfo {
  position: fixed;
  top: 2.5%;
}

.appLoadingContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Account for permanent sidebar at above mobile widths */
@media screen and (min-width: 600px) {
  .map {
    height: 100vh;
    margin-left: 240px;
  }

  .centeredContainer {
    margin-left: 235px;
    width: calc(100% - 235px);
  }
}
